<template>
  <app-module-view>
    <template slot="body">
      <div class="row">
        <div class="col-lg-2">
          <div class="card">
            <div class="card-body">
              <div style="height: 280px;">
                <h3 class="text-center">{{ $t('dashboard.people') }}</h3>
                <form class="form-inline">
                  <label
                    class="my-1 mr-2"
                    for="select_top_page_site">{{ $t('dashboard.select_site') }}:</label>
                  <app-select
                    v-model="selectedSite"
                    :options="topPageSites"
                    id="select_top_page_site"
                    no-label
                    no-empty-value
                    disable-form-group
                  ></app-select>
                </form>
                <div style="height: 180px;">
                  <app-tachometer-chart :chart-data="tachometerData" :height="180"></app-tachometer-chart>
                </div>
                <div class="tachometer-labels">
                  <div>{{ tachometerData.labels[0] }}</div>
                  <div>
                    Max:
                    <app-tooltip :title="$t('dashboard.tooltip.peoplemeter_max')"></app-tooltip>
                    {{ tachometerData.labels[1] }}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="col-lg-10">
          <div class="card">
            <div class="card-body">
              <div style="height: 280px;">
                <button
                  type="button"
                  class="btn btn-secondary"
                  :class="{'active': this.lineChartMode === 'userViews'}"
                  @click="setLineChartMode('userViews')">{{ $t('dashboard.people') }}
                </button>
                <button
                  type="button"
                  class="btn btn-secondary"
                  :class="{'active': this.lineChartMode === 'pageViews'}"
                  @click="setLineChartMode('pageViews')">{{ $t('kpi.kpi_parameters.pageViews') }}
                </button>
                <button
                  type="button"
                  class="btn btn-secondary"
                  :class="{'active': this.lineChartMode === 'videoViews'}"
                  @click="setLineChartMode('videoViews')">{{ $t('videoStats.videoplays') }}
                </button>
                <app-line-chart :chart-data="lineChartData" :height="250" :max-labels="24"></app-line-chart>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="row">
        <div class="col-lg-12">
          <div class="alert alert-info historical-pv">
            <span>{{ $t('dashboard.historical_pv') }}</span>
            <button class="btn btn-success" @click="openReport">{{ $t('dashboard.show') }}</button>
          </div>
        </div>
      </div>
      <div class="row">
        <div class="col-lg-12">
          <div class="card">
            <div class="card-body">
              <h2>
                {{ $t('dashboard.top_pages') }}
                <span v-if="getAllTopPageSites === false"> - {{ getSiteTitle(selectedSite) }}</span>
                <app-checkbox-button
                  v-model="getAllTopPageSites"
                  id="toggle_getAllTopPageSites"
                  :label="$t('dashboard.all_sites')"
                  class="ml-3"></app-checkbox-button>
              </h2>
              <div class="table-responsive m-t-20">
                <table class="table stylish-table">
                  <thead>
                  <tr>
                    <th style="width:50px;" class="pr-0">
                      {{ $t('dashboard.people') }}
                      <app-tooltip position="absolute" :title="$t('dashboard.tooltip.people')"></app-tooltip>
                    </th>
                    <th style="width:20px;" class="px-0">&nbsp;</th>
                    <th>
                      {{ $t('dashboard.article_title') }}
                      <app-tooltip position="absolute" :title="$t('dashboard.tooltip.article_title')"></app-tooltip>
                    </th>
                    <th style="width: 150px;" class="text-center">
                      {{ $t('dashboard.page_views') }}
                      <app-tooltip position="absolute" :title="$t('dashboard.tooltip.page_views')"></app-tooltip>
                    </th>
                    <th style="width: 90px;">
                      {{ $t('dashboard.today_page_views') }}
                      <app-tooltip position="absolute" :title="$t('dashboard.tooltip.today_page_views')"></app-tooltip>
                    </th>
                    <th style="width: 150px;" class="text-center">
                      {{ $t('dashboard.video_views') }}
                      <app-tooltip position="absolute" :title="$t('dashboard.tooltip.video_views')"></app-tooltip>
                    </th>
                    <th style="width: 90px;">
                      {{ $t('dashboard.today_video_views') }}
                      <app-tooltip position="absolute" :title="$t('dashboard.tooltip.today_video_views')"></app-tooltip>
                    </th>
                    <th>&nbsp;</th>
                  </tr>
                  </thead>
                  <tbody>
                  <tr v-for="(topPage, index) in topPageData"
                      :key="`item-${index}`"
                      :class="{'row-down': topPage.hasOwnProperty('orderChangeDown'), 'row-up': topPage.hasOwnProperty('orderChangeUp') }">
                    <td class="pr-0">{{ topPage.peopleCnt }}</td>
                    <td class="px-0">
                      <em
                        :title="$t('dashboard.tooltip.order_change_up')"
                        class="fa fa-arrow-up"
                        aria-hidden="true"
                        v-if="topPage.hasOwnProperty('orderChangeUp')"
                        style="color: #41b883;">&nbsp;</em>
                      <em
                        :title="$t('dashboard.tooltip.order_change_down')"
                        class="fa fa-arrow-down"
                        aria-hidden="true"
                        v-if="topPage.hasOwnProperty('orderChangeDown')"
                        style="color: #fc4b6c;">&nbsp;</em>
                      <em
                        :title="$t('dashboard.tooltip.order_change_none')"
                        class="fa fa-dot-circle"
                        aria-hidden="true"
                        v-if="topPage.hasOwnProperty('orderChangeNone')"
                        style="color: #1693c1;">&nbsp;</em>
                    </td>
                    <td>
                      <div>
                        <strong>{{ topPage.title }}</strong>
                      </div>
                      <div>
                                                <span
                                                  class="text-secondary mr-3"
                                                  :title="$t('dashboard.tooltip.order_date')">
                                                    {{ topPage.orderDate | prettyDateTime }}</span>
                        <span :title="$t('dashboard.tooltip.picture_count')">
                                                    <i class="fas fa-images"></i> {{ topPage.imageCount }}
                                                </span>
                        <span class="ml-1" :title="$t('dashboard.tooltip.video_count')">
                                                    <i class="fas fa-video"></i> {{ topPage.videoCount }}
                                                </span>
                        <span
                          v-if="getAllTopPageSites"
                          class="ml-3 badge"
                          :class="getSiteBadgeClass(topPage.siteName)"
                        >{{ getSiteTitle(topPage.siteName) }}</span>
                      </div>
                    </td>
                    <td class="p-0">
                      <app-mini-line-chart
                        :width="150"
                        :height="65"
                        :chart-data="getPageViewsChartData(topPage)"></app-mini-line-chart>
                    </td>
                    <td class="text-center">{{ topPage.articleViewToday | formatNumber }}</td>
                    <td class="p-0">
                      <app-mini-line-chart
                        :width="150"
                        :height="65"
                        :chart-data="getVideoStatsChartData(topPage)"></app-mini-line-chart>
                    </td>
                    <td class="text-center">{{ topPage.videoViewToday | formatNumber }}</td>
                    <td>
                      <router-link
                        tag="a"
                        target="_blank"
                        class="btn btn-default btn-sm"
                        :title="$t('route.article_detail')"
                        :to="{ name: 'articleByDocumentId', params: { id: topPage.articleDocId }}"
                      >
                        <i class="fa fa-info"></i>
                      </router-link>
                    </td>
                  </tr>
                  </tbody>
                </table>
              </div>
            </div>
          </div>
          <p class="info">{{ $t('dashboard.tooltip.page_video_views_hint') }}</p>
        </div>
      </div>
    </template>
  </app-module-view>
</template>

<script>
import ModuleView from '@/components/ModuleView'
import EdonApi from '@/api/edon'
import Select from '@/components/form/select/Select'
import { AVAILABLE_SITES, SITE_MAP, REPORT_URL } from '@/model/ValueObject/TopPageSites'
import LineChart from '@/components/chart/LineChart'
import TachometerChart from '@/components/chart/TachometerChart'
import MiniLineChart from '@/components/chart/MiniLineChart'
import CheckboxButton from '@/components/form/inputs/InputCheckBox'
import Tooltip from '@/components/tooltip/Tooltip'

export default {
  name: 'DashboardView',
  data () {
    return {
      selectedSite: 'plus1',
      getAllTopPageSites: false,
      trackOrderChanges: true,
      topPageSites: AVAILABLE_SITES,
      topPageData: [],
      topPageDataPrev: [],
      lastDashboard: null,
      topPagesLoopInterval: null,
      lineChartMode: 'userViews',
      lineChartData: {
        labels: [],
        datasets: []
      },
      tachometerData: {
        labels: [],
        datasets: []
      }
    }
  },
  methods: {
    getSiteTitle (siteName) {
      return SITE_MAP[siteName]
    },
    getSiteBadgeClass (siteName) {
      return 'badge-' + siteName
    },
    getTopPages () {
      EdonApi().get('/dashboard/' + this.selectedSite)
        .then(response => {
          if (this.trackOrderChanges === true) {
            this.topPageDataPrev = this._.cloneDeep(this.topPageData)
          }
          if (this.trackOrderChanges === false) {
            this.topPageDataPrev = []
            this.trackOrderChanges = true
          }
          if (response.status === 204) {
            return
          }
          this.lastDashboard = response.data
          this.setOrderChangeClass()
          this.setLineChartData()
          this.tachometerData = {
            labels: [
              response.data.curPeople,
              response.data.maxPeople
            ],
            datasets: [
              {
                data: [response.data.curPeople, response.data.maxPeople - response.data.curPeople],
                backgroundColor: [
                  '#ffb22b',
                  'rgba(204, 204, 204, 0.5)'
                ]
              }
            ]
          }
        })
        .catch(error => {
          console.log(error)
        })
    },
    setLineChartMode (mode) {
      this.lineChartMode = mode
      this.setLineChartData()
    },
    trimZeroMetrics (metrics) {
      let idx = metrics.length - 1
      while (metrics[idx] === 0) idx--
      return metrics.slice(0, idx + 1)
    },
    setLineChartData () {
      const datasetLabelToday = this.lastDashboard[this.lineChartMode + 'TodayChart'].label
      const datasetLabelLastWeek = this.lastDashboard[this.lineChartMode + 'LastWeekChart'].label
      const datasetMetricsToday = this.trimZeroMetrics(this.lastDashboard[this.lineChartMode + 'TodayChart'].metrics)
      const datasetMetricsLastWeek = this.lastDashboard[this.lineChartMode + 'LastWeekChart'].metrics
      const datasetColor = {
        userViewsBorder: '#ffb22b',
        userViewsBackground: 'rgba(255, 178, 43, .4)',
        pageViewsBorder: '#f87979',
        pageViewsBackground: 'rgba(248, 121, 121, 0.4)',
        videoViewsBorder: '#1693c1',
        videoViewsBackground: 'rgba(22, 147, 193, 0.4)'
      }

      this.lineChartData = {
        labels: [
          '00:00', '00:15', '00:30', '00:45', '01:00', '01:15', '01:30', '01:45', '02:00', '02:15', '02:30',
          '02:45', '03:00', '03:15', '03:30', '03:45', '04:00', '04:15', '04:30', '04:45', '05:00', '05:15',
          '05:30', '05:45', '06:00', '06:15', '06:30', '06:45', '07:00', '07:15', '07:30', '07:45', '08:00',
          '08:15', '08:30', '08:45', '09:00', '09:15', '09:30', '09:45', '10:00', '10:15', '10:30', '10:45',
          '11:00', '11:15', '11:30', '11:45', '12:00', '12:15', '12:30', '12:45', '13:00', '13:15', '13:30',
          '13:45', '14:00', '14:15', '14:30', '14:45', '15:00', '15:15', '15:30', '15:45', '16:00', '16:15',
          '16:30', '16:45', '17:00', '17:15', '17:30', '17:45', '18:00', '18:15', '18:30', '18:45', '19:00',
          '19:15', '19:30', '19:45', '20:00', '20:15', '20:30', '20:45', '21:00', '21:15', '21:30', '21:45',
          '22:00', '22:15', '22:30', '22:45', '23:00', '23:15', '23:30', '23:45', '24:00'
        ],
        datasets: [
          {
            label: this.$options.filters.prettyDate(datasetLabelToday),
            borderColor: datasetColor[this.lineChartMode + 'Border'],
            backgroundColor: datasetColor[this.lineChartMode + 'Background'],
            data: datasetMetricsToday
          },
          {
            label: this.$options.filters.prettyDate(datasetLabelLastWeek),
            backgroundColor: 'rgba(204, 204, 204, 0.5)',
            data: datasetMetricsLastWeek
          }
        ]
      }
    },
    getTopPagesInLoop () {
      this.topPagesLoopInterval = setInterval(() => {
        this.getTopPages()
      }, 10000)
    },
    setOrderChangeClass () {
      let topPageData = []
      if (this.getAllTopPageSites === true) {
        topPageData = this.lastDashboard.allTopArticles
      }
      if (this.getAllTopPageSites === false) {
        topPageData = this.lastDashboard.topArticles
      }

      let prevPosition = 0
      this.topPageDataPrev.forEach((topPagePrev) => {
        prevPosition++
        let curPosition = 0
        topPageData.forEach((topPageCur) => {
          curPosition++
          if (topPagePrev.articleDocId === topPageCur.articleDocId) {
            if (prevPosition < curPosition) {
              topPageCur.orderChangeDown = true
            }
            if (prevPosition > curPosition) {
              topPageCur.orderChangeUp = true
            }
            if (prevPosition === curPosition) {
              topPageCur.orderChangeNone = true
            }
          }
        })
      })
      if (this.topPageDataPrev.length > 0) {
        topPageData.forEach((topPageCur) => {
          let existsInPrev = false
          this.topPageDataPrev.forEach((topPagePrev) => {
            if (topPagePrev.articleDocId === topPageCur.articleDocId) {
              existsInPrev = true
            }
          })
          if (existsInPrev === false) {
            topPageCur.orderChangeUp = true
          }
        })
      }

      this.topPageData = topPageData
    },
    getVideoStatsChartData (topPage) {
      return {
        labels: topPage.videoViewChart.dimensions,
        datasets: [
          {
            borderColor: '#1693c1',
            backgroundColor: 'rgba(22, 147, 193, 0.4)',
            data: topPage.videoViewChart.metrics
          }
        ]
      }
    },
    getPageViewsChartData (topPage) {
      return {
        labels: topPage.articleViewChart.dimensions,
        datasets: [
          {
            borderColor: '#f87979',
            data: topPage.articleViewChart.metrics,
            backgroundColor: 'rgba(248, 121, 121, 0.4)'
          }
        ]
      }
    },
    getAll () {
      this.getTopPages()
      this.getTopPagesInLoop()
    },
    stopAll () {
      clearInterval(this.topPagesLoopInterval)
    },
    openReport () {
      window.open(REPORT_URL[this.selectedSite])
    }
  },
  watch: {
    selectedSite () {
      this.stopAll()
      this.trackOrderChanges = false
      this.getAll()
    },
    getAllTopPageSites () {
      this.trackOrderChanges = false
      this.setOrderChangeClass()
    }
  },
  components: {
    appModuleView: ModuleView,
    appSelect: Select,
    appCheckboxButton: CheckboxButton,
    appLineChart: LineChart,
    appTachometerChart: TachometerChart,
    appMiniLineChart: MiniLineChart,
    appTooltip: Tooltip
  },
  mounted () {
    const defaultSite = this.$store.getters['site/all'].find(
      (site) => site.id === this.$store.getters['user/currentUser'].defaultSite
    )
    if (defaultSite && SITE_MAP[defaultSite.name]) {
      this.selectedSite = defaultSite.name
    }
  },
  created () {
    this.getAll()
  },
  beforeDestroy () {
    this.stopAll()
  }
}
</script>

<style scoped>
.badge-plus1 {
  background-color: #1693c1;
  color: #fff;
}

.badge-plus7 {
  background-color: #0064ad;
  color: #fff;
}

.badge-emma {
  background-color: #c19d6a;
  color: #fff;
}

.badge-sport24 {
  background-color: #00c129;
  color: #fff;
}

.badge-sarm {
  background-color: #b7c100;
  color: #fff;
}

.badge-polovnictvoarybarstvo {
  background-color: #b3a981;
  color: #fff;
}

.badge-autobild {
  background-color: #e32021;
  color: #fff;
}

.badge-ncpz {
  background-color: #ec1d24;
  color: #fff;
}

.badge-eva {
  background-color: #000000;
  color: #fff;
}

.badge-zivot {
  background-color: #d90429;
  color: #fff;
}

.row-up {
  width: 100%;
  height: 100%;
  animation: blick-up;
  animation-duration: 2s;
  animation-iteration-count: 1;
  animation-timing-function: ease-out;
}

@keyframes blick-up {
  from {
    background-color: rgba(0, 255, 0, 0.2);
  }
  to {
    background-color: transparent;
  }
}

.row-down {
  width: 100%;
  height: 100%;
  animation: blick-down;
  animation-duration: 2s;
  animation-iteration-count: 1;
  animation-timing-function: ease-out;
}

@keyframes blick-down {
  from {
    background-color: rgba(255, 0, 0, 0.2);
  }
  to {
    background-color: transparent;
  }
}

.tachometer-labels {
  position: relative;
  width: 100%;
}

.tachometer-labels div {
  position: absolute;
  text-align: center;
  width: 100%;
}

.tachometer-labels div:first-child {
  top: -55px;
  font-weight: bold;
  font-size: 20px;
}

.tachometer-labels div:last-child {
  top: -20px;
  font-size: 14px;
}

.pr-0 {
  white-space: nowrap;
}

.info {
  margin-top: -10px;
  margin-bottom: 0;
}

.historical-pv {
  display: flex;
  align-items: center;
  justify-content: space-between;
}
</style>
