import { Line, mixins } from 'vue-chartjs'

const moment = require('moment')

export default {
  extends: Line,
  mixins: [mixins.reactiveProp],
  data () {
    return {
      options: {
        responsive: true,
        maintainAspectRatio: false,
        scaleBeginAtZero: true,
        layout: {
          padding: {
            top: 5,
            bottom: -10
          }
        },
        elements: {
          point: {
            radius: 0,
            hitRadius: 5,
            hoverRadius: 5
          }
        },
        legend: {
          display: false
        },
        tooltips: {
          enabled: true,
          mode: 'nearest',
          intersect: false,
          displayColors: false,
          callbacks: {
            label: (tooltipItems) => {
              const time = moment(tooltipItems.xLabel).format('HH:mm')

              return time + ' : ' + tooltipItems.yLabel
            },
            title: () => {
              return null
            }
          }
        },
        scales: {
          xAxes: [
            {
              gridLines: {
                display: false,
                drawBorder: false
              },
              ticks: {
                display: false
              }
            }
          ],
          yAxes: [
            {
              gridLines: {
                display: false,
                drawBorder: false
              },
              ticks: {
                min: 0,
                display: false
              }
            }
          ]
        }
      }
    }
  },
  mounted () {
    this.renderChart(this.chartData, this.options)
  }
}
