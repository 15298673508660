import { Doughnut, mixins } from 'vue-chartjs'

export default {
  extends: Doughnut,
  mixins: [mixins.reactiveProp],
  data () {
    return {
      options: {
        maintainAspectRatio: false,
        layout: {
          padding: {
            top: 0,
            bottom: -10
          }
        },
        legend: {
          display: false
        },
        tooltips: {
          enabled: true,
          mode: 'single',
          callbacks: {
            label: (tooltipItems, data) => {
              return data.labels[tooltipItems.index]
            }
          }
        },
        rotation: Math.PI,
        circumference: Math.PI
      }
    }
  },
  mounted () {
    this.renderChart(this.chartData, this.options)
  }
}
